<template>
  <div class="page-container marker-page">
    <portal to="page-name">Cadastro de marcador</portal>
    <div class="loading loading-lg mt-2" v-if="loading" />
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="form-group float-right status-toggle">
          <label class="form-switch">
            <input type="checkbox" v-model="form.active">
            <i class="form-icon"></i> Registro ativo
          </label>
        </div>
        <h1 class="card-title">Cadastro de marcador</h1>
      </div>
      <div class="card-body">
        <div class="columns form-group">
          <div class="column col-12 form-group"
               :class="{'has-error': $v.form.name.$error}">
            <label for="name" class="form-label">Nome</label>
            <input type="text" id="name" name="name"
                   v-model="form.name" class="form-input"
                   @change="$v.form.name.$touch()">
            <template v-if="$v.form.name.$error">
              <div class="form-input-hint"
                   v-if="!$v.form.name.required">Campo obrigatório</div>
            </template>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1" @click="save()"
                :disabled="saving" :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/src/validators';
import formMixin from 'src/mixins/form';
import { mergeFrom } from '@/helpers/object';

export default {
  mixins: [formMixin],
  data() {
    return {
      isNew: true,
      loading: false,
      saving: false,
      form: this.blankForm(),
    };
  },
  async mounted() {
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  validations() {
    return {
      form: {
        name: { required },
        active: { required },
      },
    };
  },
  methods: {
    load() {
      this.loading = true;

      return this.$http
        .get(`/markers/${this.form.id}`)
        .then(({ data }) => {
          this.form = mergeFrom(this.blankForm(), data);
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }

      const params = {
        name: this.form.name,
      };

      const { data: found } = await this.$http.get('/markers', { params });

      if (found.items.length > 0 && found.items[0].id !== this.form.id) {
        this.$toast.show('Marcador já adicionado', { type: 'error' });
        return;
      }

      this.saving = true;

      const place = this.clone(this.form);

      const request = this.isNew
        ? this.$http.post('/markers', place)
        : this.$http.put(`/markers/${this.form.id}`, place);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/markers/${data.id}/edit`);
            this.isNew = false;
            this.form.id = data.id;
          }
          this.$toast.show('Registro salvo');
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    blankForm() {
      return {
        id: '',
        name: '',
        active: true,
      };
    },
  },
};
</script>

<style lang="scss">
  .marker-page {
  }
</style>
